
//bootstrap mixins
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";

@import "partials/mixins";

@import "partials/components/linkSpanner";
@import "partials/components/scroll";

$c-blue: #0057c0;
$c-primary: #0eacdb;

body{
	font-family: 'cwTeXYen',  sans-serif;
}

a:hover{
	text-decoration: none;
}

header{
	background: #fff;
	text-align: center;
	padding: 10px 0;

	h2{
		letter-spacing: -2px;
	}
}

.navbar-mob-slide{
	position:relative;
	top:0px;
	width:100%;
    z-index: 1;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.15) ;
}

.navbar{
	padding-top: 10px;
	padding-bottom: 10px;


	&-main{
		li{
			@include media-breakpoint-up(md) {
				padding-left: 0.5rem;
				padding-right: 0.5rem;
			}
		}
	}
}
.navbar-brand img{
	@media (max-width: 1342px){
		width: 260px;
	}
	@media (max-width: 1289px){
		width: 200px;
	}
	@include media-breakpoint-only(xs) {
		width: 200px;
	}
}

//navbar mobile
.navbar-mobile{
	position: fixed;
	bottom: 0;
	text-align: center;
	// height: 70px;
	width: 100%;
	z-index: 10;
	padding-left: 15px;
	padding-right: 15px;
	
	@include gradient-y(#0eacdb,  #094da0);

	.col{
		padding: 10px 0;
	}

	.active{
		background: #0eacdb;
	}

	a{
		display: block;
		color: #fff;
		font-size: 0.75rem;
	}
	img{
		display: block;
		margin: 0 auto;
		width: 40px;
	}
}

.navbar-toggler{
	// Bars
	height: 40px;
	 .icon-bar {
	   display: block;
	   width: 22px;
	   height: 2px;
	   border-radius: 1px;
	   background-color: #000;
	   transition: all 0.2s;
	 }
	 .icon-bar + .icon-bar {
	   margin-top: 4px;
	 }
	 .top-bar {
	     transform: rotate(45deg);
	     transform-origin: 10% 10%;
	   }
	   .middle-bar {
	     opacity: 0;
	   }
	   .bottom-bar {
	     transform: rotate(-45deg);
	     transform-origin: 10% 90%;
	   }

	   &.collapsed {
	     .top-bar {
	       transform: rotate(0);      
	     }
	     .middle-bar {
	       opacity: 1;
	     }
	     .bottom-bar {
	       transform: rotate(0);
	     }
	   }
}
 

//footer
footer{
	color: #030000;

	@include media-breakpoint-down(md) {
		padding-bottom: 70px;
	}
}
.footer{
	&-info{
		font-size: 1.25rem;
		font-weight: 500;
		line-height: 1.5em;
		@include media-breakpoint-down(md) {
			text-align: center;
		}

		&-text{

			margin-top: 1rem;
			margin-left: 0.5rem;

			@include media-breakpoint-down(md){
				font-size: 0.75rem;
				margin-top: -10px;
				margin-bottom: 30px;
				line-height: 1.5em;
			}

		}

		img{
			@include media-breakpoint-down(md){
				width: 100px;
			}
		}
	}
	&-copyright{
		text-align: right;
		position: absolute;
		bottom: 32px;
		right: 0;
		@include media-breakpoint-down(md) {
			font-size: 0.75rem;
			position: relative;
			text-align: center;
			bottom: auto;
			margin-bottom: 20px;
		}
	}
}


.page-title{
	padding-bottom: 1rem;
	border-bottom: 1px dotted #aaa;
	margin: 3rem 0 2rem;
	font-weight: 300;
}

//index
.index-banner{
	background-size: cover;
	background-position: center center;
	min-height: 10em;
	height: 60vh;
	width: 100%;
	margin-top: 40px;
	// @include image-height-padding(1280, 500);
	// 
	@include media-breakpoint-up(md) {
		height: 500px;
	}

	&:after{
		content: '';
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.3);
		position: absolute;
		top: 0;
	}

	h1{
		font-family: serif;
		text-shadow: 0 0.5rem 0.5rem rgba(0,0,0,0.6);
		@include media-breakpoint-down(md) {
			font-size: 2rem;
		}
		@include media-breakpoint-down(sm) {
			font-size: 1.5rem;
			padding: 0 2%;
		}
	}

	&-text{
		position: relative;
		z-index: 2;
	}
}

.page-banner{
	margin-top: 40px;
	background-size: cover;
	background-position: center center;
	min-height: 10em;
	height: 250px;
	width: 100%;
	// @include image-height-padding(1280, 500);
	// 
	@include media-breakpoint-up(md) {
		height: 400px;
		// margin-top: 100px;
	}
}


.bg-cover{
	background-size: cover;
	background-position: center center;
	width: 100%;
	// padding-bottom: 40%;
}

//btn icon
.btn-mb-svg{
	width: 200px;
	position: relative;

	&:hover #btn-mb, &:hover #btn-mb-orange{
		#head{
			transform: translate(-2px);
			transition: 200ms;
		}
		
		#sting{
			transform: translate(2px);
			transition: all ease-in-out 200ms ;
		}
		
	}

	svg{
		width: 200px;

	}
	//inline version
	svg:hover{
		#head{
			transform: translate(-2px);
			transition: 200ms;
		}
		#sting{
			transform: translate(2px);
			transition: all ease-in-out 200ms ;
		}

	}
}

.icon{
	&-before{
		width: 125px;
		height: 52px;
	}
}

//section
.section{

	&-intro{
		position: relative;
		@include media-breakpoint-only(xs) {
			margin-bottom: 5rem !important;
			border-bottom: 2px solid #e8e8e8;
		}
	}

	&-intro-side{
		figure{
			width: 70%;
			margin-left: auto;
			margin-right: auto;

			@include media-breakpoint-up(md) {
				width: 80%;
			}	
		}
	}
	&-left{
		@include media-breakpoint-up(sm) {
			border-right: 1px solid #e8e8e8;
		}
	}
	&-right{
		@include media-breakpoint-up(sm) {
			border-left: 1px solid #e8e8e8;
		}
	}
}

.section-title{
	color: #1f263a;
}

.intro-text{
	h3{
		font-size: 1.5rem;
	}
	p{
		color: #8f929c;
	}
}


// link
.link{
	&-blue{
		color: $c-blue;
	}
}


// spacing

.mt-100{
	margin-top: 100px;
}
.mb-100{
	margin-bottom: 100px;
}

.pt-100{
	padding-top: 100px;
}
.pb-100{
	padding-bottom: 100px;
}

// typography
p{
	font-size: 1.125rem;
	font-weight: 300;
	line-height: 2rem;

	@include media-breakpoint-down(md) {
		font-size: 1rem;
		line-height: 1.8rem;
	}
}


.container-custom{
	padding-left:7%;
	padding-right:7%;

	&.navbar{
		@include media-breakpoint-down(md) {
			// padding: 0 1%;
			padding: 0;
		}
	}
}

.angle-left:before{
	@include triangle(left,10px,10px,#fff);
	position: absolute;
	left: -10px;
	top: 100px;

	@include media-breakpoint-down(md) {
		display: none;
	}
}
.angle-right:before{
	@include triangle(right,10px,10px,#fff);
	position: absolute;
	right: -10px;
	top: 100px;
	@include media-breakpoint-down(md) {
		display: none;
	}
}



.overlay{
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.5);
	position: absolute;
	top: 0;

}

.test{
	// @include image-height-padding(856,769);

	@include image-height-padding(438,312);

}


//map
.map-frame {
    width: 100%;
    height: 500px;
    position: relative;
    @include media-breakpoint-down(md) {
    	height: 250px;
    }
}


//video
.video-item{
	margin-bottom: 30px;
	@include media-breakpoint-down(md) {
		margin-bottom: 15px;
	}

	&-half{
		height: calc(50% - 1rem) ;
		@include media-breakpoint-down(md) {
			min-height: 200px;
			padding: 0;
		}
	}
}
.video-img-holder{
	// height: 0;
	// padding-bottom: 72%;
	// @include media-breakpoint-up(md) {
	// 	padding-bottom: 35%;
	// }

	.icon-play{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		right: 0;
		text-align: center;
	}

	.video-btn-group{
		position: absolute;
		bottom: 10px;
		left: 10px;
	}
}



.video-info{
	text-align: center;

	@include media-breakpoint-down(md) {
		text-align: left;
		padding: 1rem;

	}

	&-logo{
		width: 70px;
		margin-bottom: 10px;

		@include media-breakpoint-down(lg) {
			width: 60px;
		}

		@include media-breakpoint-down(md) {
			float: left; 
			width: 60px;
			margin-bottom: 0;
		}
	}

	&-name{
		margin-bottom: 10%;

		@include media-breakpoint-down(md) {
			margin-left: 5px;
			margin-bottom: 0;
			float: left; 
			transform: scale(0.8);
		}
	}

	&-share{

		img{
			width: 28px;
		}
		@include media-breakpoint-down(md) {
			float: right;
			transform: translateY(50%);
		}

		@media (max-width: 500px) {
			float: left;
			width: 100%;
		}
	}
}

//room
.index-room{
	&-large{
		padding: 0;
	}

	&-half{
		@include media-breakpoint-down(md) {
			padding: 0;
		}
	}
}
.card-room{
	&-info{
		padding: 8% 10%;

		@include media-breakpoint-down(lg) {
			padding: 4% 5%;
		}

		.btn{
			position: absolute;
			bottom: 10%;
		}

		h4{
			font-size: 1.25rem;
			margin-bottom: 0.5rem;

			@include media-breakpoint-down(md) {
				margin-bottom: 0.25rem;
			}
		}

		p{
			@include media-breakpoint-down(md) {
				font-size: 0.875rem;
			}
		}
	}

	&-info-overlay{
		position:absolute;
		bottom: 0;
		width: 100%;
		background: rgba( 255,255,255,0.85);
		padding: 5% 8%;

		@include media-breakpoint-down(md) {
			padding: 2% 4%;
		}

		h4{
			font-size: 1.25rem;
			margin-bottom: 0.5rem;

			@include media-breakpoint-down(md) {
				margin-bottom: 0.25rem;
			}
		}
	}
}


//room list
.room-list-wrapper{

	.room-image{
		padding-bottom: 25%;
		@include media-breakpoint-down(lg) {
			padding-bottom: 40%;
		}
	}

	.btn{
		left: 0;
		right: 0;
		margin: 0 auto;
		width: 140px;

		@include media-breakpoint-down(md) {
			position: relative;
		}
	}

	.room-info{
		margin-top: 2%;
		margin-bottom: 2%;
		
		position: relative;
		z-index: 2;
		background: rgba( 255,255,255, 0.8);

		@include media-breakpoint-down(xs) {
			margin:0;
		}

	}

	.room-item:nth-child(odd){
		
		.room-info{
			@include media-breakpoint-up(md){
				margin-left: -5%;
				margin-right: auto;
			}
			
		}
	}
	.room-item:nth-child(even){

		.room-image{

			@include media-breakpoint-up(md) {
				order: 2;
			}
		}
		.room-info{
			@include media-breakpoint-up(md){
				margin-right: -5%;
				margin-left: auto;
			}
			@include media-breakpoint-down(up) {
				order: 1;
			}


			
		}
	}
}



.list-border-right{
	li {
		a{
			border-right: 1px solid #aaa;
			padding-right: 1rem;
		}
		
		&:last-child a{
			border: none;
		}
	}
}
.index-about-list{
	
	@media (min-width: 1289px){
		margin-top: -70px;
		float: right;
	}
}

//contact
.contact-info{
	margin-top: 150px;
	position: relative;

	@include media-breakpoint-down(md) {
		margin-top: 100px;
	}
	&:after{
		content: '';
		position: absolute;
		top: -45px;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		background: #094da0;
		width: 80%;
		height: 45px;
		z-index: 0;
		border-top-right-radius: 5px;
		border-top-left-radius: 5px;
		
		@include media-breakpoint-down(md) {
			display: none;
		}

	}
}

//title
.title-style{
	@include media-breakpoint-down(md) {
		text-align: center;
		
		left: 0;
		right: 0;
		span{
			margin: 0 auto;
			&:after{
				left: 0;
				right: 0;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}
.title-border-bm-left{
	
	span{
		margin-top: 10px;
		display: block;
		width: 100px;
		border-bottom: 1px solid #ddd;
		position: relative;
		&:after{
			content: '';
			display: block;
			width: 30px;
			height: 4px;
			background: #0eacdb;
			position: absolute;
			top: -2px;
		}
	}
	
}
.title-border-bm-center{
	span{
		margin-top: 10px;
		display: block;
		width: 100px;
		margin-left: auto;
		margin-right: auto;
		border-bottom: 1px solid #ddd;
		position: relative;
		&:after{
			content: '';
			display: block;
			width: 30px;
			height: 4px;
			background: #0eacdb;
			position: absolute;
			top: -2px;
			left: 0;
			right: 0;
			margin-left: auto;
			margin-right: auto;
		}
	}
	
}

//about
.about-tab{
	a{
		color: #fff !important;
		background: $c-primary;
		margin: 0 4% 5%;
		border-radius: 0.25rem !important;

		&.active{
			background: darken($c-primary, 8%) !important;
		}
	}
}

//modal

.modal-booking{

	.modal-content{
		background: #000;
		color: #ddd;
		border-radius: 0;
		border: 5px solid rgba(255,255,255,0.4);
	}

}


/*===============
  Room Details
 ============== */
.large-img {
  display: inline-flex; }
  @media (max-width: 991.98px) {
  .large-img {
    display: block; } }
  
  .room-large {
    padding: 0;
    height: max-content; }
    .room-large .viewicon {
      position: absolute;
      right: 15px;
      bottom: 15px;
      display: none; }
  

  .room-half {
    scroll-behavior: smooth;
    overflow: scroll;
    height: 76%; }
    @media (max-width: 991.98px) {
    .room-half {
      padding: 0;
      display: flex;
      overflow-x: scroll;
      height: fit-content; } }

      .video-item-one-thrid {
        height: calc(34.3% - 1rem);
        cursor: pointer; }
        @media (max-width: 991.98px) {
        .video-item-one-thrid {
          -webkit-box-flex: 0;
          -webkit-flex: 0 0 33.33%;
          -ms-flex: 0 0 33.33%;
          flex: 0 0 33.33%;
          max-width: 33.33%;
          padding: 0 4px; } }
          .video-item-one-thrid#view-show:hover .overlay {
            display: none; }
          .video-item-one-thrid .overlay img {
            width: 30%;
            display: block;
            margin: 0 auto;
            top: 30%;
            position: relative; }

.nav-pills .nav-item { 
  padding: 0;
  cursor: pointer;
  /*clip-path: polygon(0% 0%, 100% 0%, 100% 82%, 54% 82%, 51% 100%, 48% 82%, 0 81%);*/ }
.nav-pills .nav-link.active:hover {
  color: #ffffff; }
  .nav-pills .nav-link { 
    border-radius: 0rem;
    color: #000000; }
  .nav-pills span {
    margin-top: 0px; }

.tab-content b{
  color: #0eacdb; }
  .tab-pane li {
    line-height: 30px;
  }

.price {
  border-collapse: collapse;
  width: 100%;
  padding-top: 20px; }
  .price td,
  .price th {
    border: 1px solid #ddd;
    padding: 8px; }
  .price th {
    background-color: #0eacdb;
    color: #ffffff;
    text-align: center; }

.return-btn {
  padding: 0 0 50px 0; }
  .btn-gray {
    border-color: #000000;
    padding: 8px 40px; }
  .btn-huge {
    font-size: 26px;
    text-align: center;
    line-height: 45px;
    letter-spacing: 6px;
    border-left: 1px solid #000000;
    border-right: 1px solid #000000;
    padding: 0 26px;
    border-radius: 0rem; }
    .btn-huge span {
      font-size: 22px;
      font-weight: lighter;
      letter-spacing: 0.5px;
      display: block;
      width: 100%;
      border-bottom: 1px solid #000000;
      position: relative;
      text-transform: uppercase; }

.book-now {
  padding-top: 20px;
  background-image: url('../images/rooms/book_now.png'); 
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; 
  height: 140px; 
  }

.price {
  border-collapse: collapse;
  width: 100%;
  padding-top: 20px; }
  .price td,
  .price th {
    border: 1px solid #ddd;
    padding: 8px; }
  .price th {
    background-color: #0eacdb;
    color: #ffffff;
    text-align: center; }

.return-btn {
  padding: 0 0 50px 0; }
  .btn-gray {
    border-color: #000000;
    padding: 8px 40px; }
  .btn-huge {
    font-size: 26px;
    text-align: center;
    line-height: 45px;
    letter-spacing: 6px;
    border-left: 1px solid #000000;
    border-right: 1px solid #000000;
    padding: 0 26px;
    border-radius: 0rem; }
    .btn-huge span {
      font-size: 22px;
      font-weight: lighter;
      letter-spacing: 0.5px;
      display: block;
      width: 100%;
      border-bottom: 1px solid #000000;
      position: relative;
      text-transform: uppercase; }

.book-now {
  padding-top: 20px;
  background-image: url('../images/rooms/book_now.png'); 
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; 
  height: 140px; }
