
/*===============
  Scroll up
 ============== */
.float_btn{
   clear: both;
   position: fixed;
   margin: 0;
   right: 60px;
   bottom: 80px; 
   top: auto;
   cursor: pointer;
   z-index: 20;
   box-shadow: 3px 5px 7px 0  rgba(#999,0.5);
   transition: all .3s;
   width: 80px;
   height: 80px;
   padding: 12px;

   @include media-breakpoint-down(md) {
    bottom: 90px; 
      width: 60px;
      height: 60px;
   }

   a{
     display: block;
     width: 100%;
     height: 100%;
   }
   // img{
   //   width: 42px;
   //   margin: 0 auto;
   // }

   &:hover{
    box-shadow: 3px 5px 14px 0  rgba(#999,0.8);
   }
}

.float_line{
  background: #32b900;
}


.scrollup {
  clear: both;
  position: fixed;
  right: 60px;
  bottom: 170px;
  top: auto;
  cursor: pointer;
  z-index: 20;

  width: 80px;
  height: 80px;
  padding: 12px;
  border-radius: 50%;
  box-shadow: 3px 3px 5px 0  rgba(#1f8d96,0.3);
  background-color: #0eacdb;

  @include media-breakpoint-down(md) {
    bottom: 160px;
    padding: 7px;
     width: 60px;
     height: 60px;
  }
  
  &:hover {
    box-shadow: 3px 5px 7px 0  rgba(#147c85,0.5);
  }

  a{
    display: block;
  }

}


.float_line_modal{
  position: fixed;
  z-index: 3;
  width: 300px;
  right: 150px;
  bottom: 150px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3) !important;

  .card-header{
    background: #32b900;
    color: white;
    font-weight: lighter;

    .close{
      color: white;
      opacity: 1;
      font-size: 0.875rem;
    }
  }

  p{
    font-size: 0.875rem;
    line-height: 1.5em;
    color: #b2b2b2;
    font-weight: 400;
  }
}
